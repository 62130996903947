(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .constant('secureEntities', {
            ACTIVITY_LOG_ALL: 'ACTIVITY_LOG_ALL',
            ACTIVITY_LOG_COMMENT: 'ACTIVITY_LOG_COMMENT',
            AUTO_ASSIGN_SALES_REPS: 'AUTO_ASSIGN_SALES_REPS',
            AUTO_ASSIGN_PROJECT_COORDINATORS: 'AUTO_ASSIGN_PROJECT_COORDINATORS',
            ORDER_ALLOCATE_ITEMS: 'ORDER_ALLOCATE_ITEMS',
            SHIPMENT_EDIT: 'SHIPMENT_EDIT',
            CONTENT_BLOCKS: 'CONTENT_BLOCKS',
            COST_AND_MARGIN: 'COST_AND_MARGIN',
            COST_TABLES: 'COST_TABLES',
            CUSTOM_PRODUCT_ATTRIBUTES: 'CUSTOM_PRODUCT_ATTRIBUTES',
            DENIAL_REASONS: 'DENIAL_REASONS',
            DEVICES: 'DEVICES',
            DIVISIONS: 'DIVISIONS',
            DUE_ON: 'DUE_ON',
            FORM_INPUT_EMAILS: 'FORM_INPUT_EMAILS',
            JOB_LOCK: 'JOB_LOCK',
            JOB_QUOTE_MANUAL_OVERRIDE: 'JOB_QUOTE_MANUAL_OVERRIDE',
            GANG_PLANNING: 'GANG_PLANNING',
            INKS: 'INKS',
            LAMINATES: 'LAMINATES',
            LARGEFORMAT_DEVICES: 'LARGEFORMAT_DEVICES',
            LARGEFORMAT_OPERATIONS: 'LARGEFORMAT_OPERATIONS',
            LARGEFORMAT_PRINT_JOB_HELP_TIPS: 'LARGEFORMAT_PRINT_JOB_HELP_TIPS',
            LPJCS: 'LPJCS',
            MENUS: 'MENUS',
            MOUNT_SUBSTRATES: 'MOUNT_SUBSTRATES',
            NOTIFICATION_EMAILS: 'NOTIFICATION_EMAILS',
            OPERATIONS: 'OPERATIONS',
            ORDER_APPROVAL: 'ORDER_APPROVAL',
            ORDER_INTERNAL_NOTES: 'ORDER_INTERNAL_NOTES',
            ORDER_ITEM_ISSUE_FLAG: 'ORDER_ITEM_ISSUE_FLAG',
            ORDER_ITEM_QUANTITY_ADJUSTMENTS: 'ORDER_ITEM_QUANTITY_ADJUSTMENTS',
            ORDER_ITEM_STATUS: 'ORDER_ITEM_STATUS',
            ORDER_LEDGER: 'ORDER_LEDGER',
            ORDER_PRODUCTION_STATUS: 'ORDER_PRODUCTION_STATUS',
            ORDER_SALES_MANAGER: 'ORDER_SALES_MANAGER',
            ORDER_SHIPPING_ADDRESSES: 'ORDER_SHIPPING_ADDRESSES',
            ORDER_SHIPPING_PACKAGES: 'ORDER_SHIPPING_PACKAGES',
            ORDER_SHIPPING_VENDOR: 'ORDER_SHIPPING_VENDOR',
            ORDER_STATUS: 'ORDER_STATUS',
            ORDER_VIEW_ONLY: 'ORDER_VIEW_ONLY',
            PACKAGES: 'PACKAGES',
            PAYMENT_TRANSACTIONS: 'PAYMENT_TRANSACTIONS',
            PICK_FEES: 'PICK_FEES',
            PJCS: 'PJCS',
            PRESS_SHEETS: 'PRESS_SHEETS',
            PRINT_FILES: 'PRINT_FILES',
            PRINT_JOB_HELP_TIPS: 'PRINT_JOB_HELP_TIPS',
            PRINT_ORDERS: 'PRINT_ORDERS',
            PRINT_SUBSTRATES: 'PRINT_SUBSTRATES',
            PRODUCT_IMAGES: 'PRODUCT_IMAGES',
            PROOF_FILES: 'PROOF_FILES',
            PROOF_STATUS: 'PROOF_STATUS',
            PROOF_TYPE: 'PROOF_TYPE',
            PROOFS: 'PROOFS',
            REDIRECT_URLS: 'REDIRECT_URLS',
            REST_ADMIN_USER_APPLICATION_SYSTEM_ROLES: 'REST_ADMIN_USER_APPLICATION_SYSTEM_ROLES',
            REST_ADMIN_USER_REMEMBER_ME_TOKENS: 'REST_ADMIN_USER_REMEMBER_ME_TOKENS',
            REST_ADMIN_USER_SITES: 'REST_ADMIN_USER_SITES',
            REST_ADMIN_USER_SYSTEM_ROLES: 'REST_ADMIN_USER_SYSTEM_ROLES',
            REST_ADMIN_USERS: 'REST_ADMIN_USERS',
            REST_APPLICATION_SITE_ROLE_SECURED_ENTITIES: 'REST_APPLICATION_SITE_ROLE_SECURED_ENTITIES',
            REST_APPLICATION_SITE_ROLES: 'REST_APPLICATION_SITE_ROLES',
            REST_APPLICATION_SYSTEM_ROLE_SECURED_ENTITIES: 'REST_APPLICATION_SYSTEM_ROLE_SECURED_ENTITIES',
            REST_APPLICATION_SYSTEM_ROLES: 'REST_APPLICATION_SYSTEM_ROLES',
            REST_BEAUTY_BRANDS_PRODUCT_NUMBERS: 'REST_BEAUTY_BRANDS_PRODUCT_NUMBERS',
            REST_CATEGORIES: 'REST_CATEGORIES',
            REST_CLIENTS: 'REST_CLIENTS',
            REST_CONTENT_BLOCKS: 'REST_CONTENT_BLOCKS',
            REST_CONTENT_PAGES: 'REST_CONTENT_PAGES',
            REST_FINANCIAL_EXCEPTION_CODES: 'REST_FINANCIAL_EXCEPTION_CODES',
            REST_FINANCIAL_EXCEPTION_ENTRIES: 'REST_FINANCIAL_EXCEPTION_ENTRIES',
            REST_COST_TABLE_POINTS: 'REST_COST_TABLE_POINTS',
            REST_COST_TABLES: 'REST_COST_TABLES',
            REST_COUNTRIES: 'REST_COUNTRIES',
            REST_CREDIT_ACCOUNT_ADJUSTMENTS: 'REST_CREDIT_ACCOUNT_ADJUSTMENTS',
            REST_CREDIT_ACCOUNT_TEMPLATE_PRODUCTS: 'REST_CREDIT_ACCOUNT_TEMPLATE_PRODUCTS',
            REST_CREDIT_ACCOUNT_TEMPLATES: 'REST_CREDIT_ACCOUNT_TEMPLATES',
            REST_CREDIT_ACCOUNTS: 'REST_CREDIT_ACCOUNTS',
            REST_DENIAL_REASONS: 'REST_DENIAL_REASONS',
            REST_DEVICE_INKS: 'REST_DEVICE_INKS',
            REST_DEVICE_PRESS_SHEETS: 'REST_DEVICE_PRESS_SHEETS',
            REST_DEVICE_TYPES: 'REST_DEVICE_TYPES',
            REST_DEVICES: 'REST_DEVICES',
            REST_DIVISIONS: 'REST_DIVISIONS',
            REST_FORM_INPUT_EMAILS: 'REST_FORM_INPUT_EMAILS',
            REST_FULFILLMENT_CENTERS: 'REST_FULFILLMENT_CENTERS',
            REST_INK_TYPES: 'REST_INK_TYPES',
            REST_INKS: 'REST_INKS',
            REST_INTERNAL_NOTIFICATIONS: 'REST_INTERNAL_NOTIFICATIONS',
            REST_JOINS: 'REST_JOINS',
            REST_LAMINATE_TYPES: 'REST_LAMINATE_TYPES',
            REST_LAMINATES: 'REST_LAMINATES',
            REST_LARGE_FORMAT_DEVICES: 'REST_LARGE_FORMAT_DEVICES',
            REST_LARGE_FORMAT_OPERATION_ITEMS: 'REST_LARGE_FORMAT_OPERATION_ITEMS',
            REST_LARGE_FORMAT_OPERATIONS: 'REST_LARGE_FORMAT_OPERATIONS',
            REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_BACK_LAMINATES: 'REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_BACK_LAMINATES',
            REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_FRONT_LAMINATES: 'REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_FRONT_LAMINATES',
            REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_MOUNT_SUBSTRATES: 'REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_MOUNT_SUBSTRATES',
            REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_OPERATION_ITEMS: 'REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_OPERATION_ITEMS',
            REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_OPERATIONS: 'REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_OPERATIONS',
            REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_PRESET_DIMENSIONS: 'REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_PRESET_DIMENSIONS',
            REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_PRINT_SUBSTRATES: 'REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_PRINT_SUBSTRATES',
            REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATIONS: 'REST_LARGE_FORMAT_PRINT_JOB_CLASSIFICATIONS',
            REST_LARGE_FORMAT_TIPS: 'REST_LARGE_FORMAT_TIPS',
            REST_MENU_ITEMS: 'REST_MENU_ITEMS',
            REST_MENUS: 'REST_MENUS',
            REST_MOUNT_SUBSTRATES: 'REST_MOUNT_SUBSTRATES',
            REST_NOTIFICATION_EMAILS: 'REST_NOTIFICATION_EMAILS',
            REST_OFFERING_SERVICE_TYPES: 'REST_OFFERING_SERVICE_TYPES',
            REST_OFFERING_VISIBILITY_TYPES: 'REST_OFFERING_VISIBILITY_TYPES',
            REST_OPERATION_ITEMS: 'REST_OPERATION_ITEMS',
            REST_OPERATION_MODIFIER_TYPES: 'REST_OPERATION_MODIFIER_TYPES',
            REST_OPERATIONS: 'REST_OPERATIONS',
            REST_ORDER_CC_PAYMENT_INFOES: 'REST_ORDER_CC_PAYMENT_INFOES',
            REST_ORDER_CC_PAYMENT_STATUSES: 'REST_ORDER_CC_PAYMENT_STATUSES',
            REST_ORDER_CC_PAYMENT_TRANSACTION_ACTIONS: 'REST_ORDER_CC_PAYMENT_TRANSACTION_ACTIONS',
            REST_ORDER_CC_PAYMENT_TRANSACTION_ADJUSTMENTS: 'REST_ORDER_CC_PAYMENT_TRANSACTION_ADJUSTMENTS',
            REST_ORDER_CC_PAYMENT_TRANSACTION_LOG_ENTRIES: 'REST_ORDER_CC_PAYMENT_TRANSACTION_LOG_ENTRIES',
            REST_ORDER_CC_PAYMENT_TRANSACTIONS: 'REST_ORDER_CC_PAYMENT_TRANSACTIONS',
            REST_ORDER_CC_TYPES: 'REST_ORDER_CC_TYPES',
            REST_ORDER_INTERNAL_NOTES: 'REST_ORDER_INTERNAL_NOTES',
            REST_ORDER_ITEM_CONSUMER_PRINT_FILES: 'REST_ORDER_ITEM_CONSUMER_PRINT_FILES',
            REST_ORDER_ITEM_CONSUMER_PROOF_FILES: 'REST_ORDER_ITEM_CONSUMER_PROOF_FILES',
            REST_ORDER_ITEM_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_OPERATIONS: 'REST_ORDER_ITEM_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_OPERATIONS',
            REST_ORDER_ITEM_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_VERSIONS: 'REST_ORDER_ITEM_LARGE_FORMAT_PRINT_JOB_CLASSIFICATION_VERSIONS',
            REST_ORDER_ITEM_LARGE_FORMAT_PRINT_JOB_CLASSIFICATIONS: 'REST_ORDER_ITEM_LARGE_FORMAT_PRINT_JOB_CLASSIFICATIONS',
            REST_ORDER_ITEM_PRINT_JOB_CLASSIFICATION_OPERATIONS: 'REST_ORDER_ITEM_PRINT_JOB_CLASSIFICATION_OPERATIONS',
            REST_ORDER_ITEM_PRINT_JOB_CLASSIFICATION_VERSIONS: 'REST_ORDER_ITEM_PRINT_JOB_CLASSIFICATION_VERSIONS',
            REST_ORDER_ITEM_PRINT_JOB_CLASSIFICATIONS: 'REST_ORDER_ITEM_PRINT_JOB_CLASSIFICATIONS',
            REST_ORDER_ITEM_PRINT_JOB_QUOTE_HISTORIES: 'REST_ORDER_ITEM_PRINT_JOB_QUOTE_HISTORIES',
            REST_ORDER_ITEM_QUANTITY_ADJUSTMENTS: 'REST_ORDER_ITEM_QUANTITY_ADJUSTMENTS',
            REST_ORDER_ITEMS: 'REST_ORDER_ITEMS',
            REST_ORDER_LEDGER_ENTRIES: 'REST_ORDER_LEDGER_ENTRIES',
            REST_ORDER_LEDGER_ENTRY_BATCHES: 'REST_ORDER_LEDGER_ENTRY_BATCHES',
            REST_ORDER_LEDGERS: 'REST_ORDER_LEDGERS',
            REST_ORDER_LOG_ENTRIES: 'REST_ORDER_LOG_ENTRIES',
            REST_ORDER_LOG_ENTRY_DISMISSEDS: 'REST_ORDER_LOG_ENTRY_DISMISSEDS',
            REST_ORDER_METADATAS: 'REST_ORDER_METADATAS',
            REST_ORDER_PROMOTIONS: 'REST_ORDER_PROMOTIONS',
            REST_ORDER_SHIPMENT_ITEM_ALLOCATIONS: 'REST_ORDER_SHIPMENT_ITEM_ALLOCATIONS',
            REST_ORDER_SHIPMENT_PACKAGES: 'REST_ORDER_SHIPMENT_PACKAGES',
            REST_ORDER_SHIPMENTS: 'REST_ORDER_SHIPMENTS',
            REST_ORDER_STATUSES: 'REST_ORDER_STATUSES',
            REST_ORDER_TERMS_PAYMENT_ADJUSTMENTS: 'REST_ORDER_TERMS_PAYMENT_ADJUSTMENTS',
            REST_ORDER_USERS: 'REST_ORDER_USERS',
            REST_ORDERS: 'REST_ORDERS',
            REST_PRESS_SHEET_COLORS: 'REST_PRESS_SHEET_COLORS',
            REST_PRESS_SHEET_TYPES: 'REST_PRESS_SHEET_TYPES',
            REST_PRESS_SHEET_WEIGHTS: 'REST_PRESS_SHEET_WEIGHTS',
            REST_PRESS_SHEETS: 'REST_PRESS_SHEETS',
            REST_PRINT_JOB_CLASSIFICATION_COVER_PRESS_SHEETS: 'REST_PRINT_JOB_CLASSIFICATION_COVER_PRESS_SHEETS',
            REST_PRINT_JOB_CLASSIFICATION_COVER_SIDE_1_INKS: 'REST_PRINT_JOB_CLASSIFICATION_COVER_SIDE_1_INKS',
            REST_PRINT_JOB_CLASSIFICATION_COVER_SIDE_2_INKS: 'REST_PRINT_JOB_CLASSIFICATION_COVER_SIDE_2_INKS',
            REST_PRINT_JOB_CLASSIFICATION_DEVICES: 'REST_PRINT_JOB_CLASSIFICATION_DEVICES',
            REST_PRINT_JOB_CLASSIFICATION_OPERATION_ITEMS: 'REST_PRINT_JOB_CLASSIFICATION_OPERATION_ITEMS',
            REST_PRINT_JOB_CLASSIFICATION_OPERATIONS: 'REST_PRINT_JOB_CLASSIFICATION_OPERATIONS',
            REST_PRINT_JOB_CLASSIFICATION_PRESET_DIMENSIONS: 'REST_PRINT_JOB_CLASSIFICATION_PRESET_DIMENSIONS',
            REST_PRINT_JOB_CLASSIFICATION_PRESS_SHEETS: 'REST_PRINT_JOB_CLASSIFICATION_PRESS_SHEETS',
            REST_PRINT_JOB_CLASSIFICATION_SIDE_1_INKS: 'REST_PRINT_JOB_CLASSIFICATION_SIDE_1_INKS',
            REST_PRINT_JOB_CLASSIFICATION_SIDE_2_INKS: 'REST_PRINT_JOB_CLASSIFICATION_SIDE_2_INKS',
            REST_PRINT_JOB_CLASSIFICATIONS: 'REST_PRINT_JOB_CLASSIFICATIONS',
            REST_PRINT_SUBSTRATES: 'REST_PRINT_SUBSTRATES',
            REST_PRODUCTS: 'REST_PRODUCTS',
            REST_PRODUCT_CATEGORIES: 'REST_PRODUCT_CATEGORIES',
            REST_PRODUCT_CREATION_BEAUTY_BRANDS: 'REST_PRODUCT_CREATION_BEAUTY_BRANDS',
            REST_PRODUCT_LEDGER_ACCOUNTS: 'REST_PRODUCT_LEDGER_ACCOUNTS',
            REST_PRODUCT_SEGMENTS: 'REST_PRODUCT_SEGMENTS',
            REST_PRODUCT_SKUS: 'REST_PRODUCT_SKUS',
            REST_PRODUCTION_GROUPS: 'REST_PRODUCTION_GROUPS',
            REST_PRODUCTION_ITEM: 'REST_PRODUCTION_ITEM',
            REST_PRODUCTION_PLAN: 'REST_PRODUCTION_PLAN',
            REST_PRODUCTION_STATUSES: 'REST_PRODUCTION_STATUSES',
            REST_PRODUCTION_TASK: 'REST_PRODUCTION_TASK',
            REST_PRODUCTION_WORK_EVENT: 'REST_PRODUCTION_WORK_EVENT',
            REST_PRODUCTION_WORK_STATION: 'REST_PRODUCTION_WORK_STATION',
            REST_PROJECTS: 'REST_PROJECTS',
            REST_PROMOTION_CUSTOM_CODES: 'REST_PROMOTION_CUSTOM_CODES',
            REST_PROMOTION_OFFERINGS: 'REST_PROMOTION_OFFERINGS',
            REST_PROMOTIONS: 'REST_PROMOTIONS',
            REST_PROOF_DENIAL_REASONS: 'REST_PROOF_DENIAL_REASONS',
            REST_PROOF_PRODUCTION_RUNS: 'REST_PROOF_PRODUCTION_RUNS',
            REST_PROOF_STATUSES: 'REST_PROOF_STATUSES',
            REST_PROOFS: 'REST_PROOFS',
            REST_REGULAR_ORDER_ITEM_SITE_OVERRIDE_AMOUNT: 'REST_REGULAR_ORDER_ITEM_SITE_OVERRIDE_AMOUNT',
            REST_REGULAR_ORDER_ITEMS: 'REST_REGULAR_ORDER_ITEMS',
            REST_REWORK_PRODUCTION_RUNS: 'REST_REWORK_PRODUCTION_RUNS',
            REST_SALES_ESTIMATES: 'REST_SALES_ESTIMATES',
            REST_SECURABLE_ENTITIES: 'REST_SECURABLE_ENTITIES',
            REST_SEGMENTS: 'REST_SEGMENTS',
            REST_SHIPMENT_QUEUE_DEFINITION_ADMIN_USERS: 'REST_SHIPMENT_QUEUE_DEFINITION_ADMIN_USERS',
            REST_SHIPMENT_QUEUE_DEFINITION_SITES: 'REST_SHIPMENT_QUEUE_DEFINITION_SITES',
            REST_SHIPMENT_QUEUE_DEFINITIONS: 'REST_SHIPMENT_QUEUE_DEFINITIONS',
            REST_SHIPPING_SERVICES: 'REST_SHIPPING_SERVICES',
            REST_SHIPPING_VENDORS: 'REST_SHIPPING_VENDORS',
            REST_SITE_COUNTRIES: 'REST_SITE_COUNTRIES',
            REST_SITE_EMAIL_CONFIGURATIONS: 'REST_SITE_EMAIL_CONFIGURATIONS',
            REST_SITE_OFFERING_METADATAS: 'REST_SITE_OFFERING_METADATAS',
            REST_SITE_OFFERING_VARIANT_ADJUSTMENTS: 'REST_SITE_OFFERING_VARIANT_ADJUSTMENTS',
            REST_SITE_OFFERING_VARIANT_PRICE_TIERS: 'REST_SITE_OFFERING_VARIANT_PRICE_TIERS',
            REST_SITE_OFFERING_VARIANTS: 'REST_SITE_OFFERING_VARIANTS',
            REST_SITE_OFFERINGS: 'REST_SITE_OFFERINGS',
            REST_SITE_ORDER_APPROVER_USERS: 'REST_SITE_ORDER_APPROVER_USERS',
            REST_SITE_PICK_FEE_GROUPS: 'REST_SITE_PICK_FEE_GROUPS',
            REST_SITE_PICK_FEE_TIERS: 'REST_SITE_PICK_FEE_TIERS',
            REST_SITE_REDIRECT_URLS: 'REST_SITE_REDIRECT_URLS',
            REST_SITE_ROLE_SECURED_ENTITIES: 'REST_SITE_ROLE_SECURED_ENTITIES',
            REST_SITE_ROLES: 'REST_SITE_ROLES',
            REST_SITE_SCALING_MARKUP_POINTS: 'REST_SITE_SCALING_MARKUP_POINTS',
            REST_SITE_SCALING_MARKUPS: 'REST_SITE_SCALING_MARKUPS',
            REST_SITE_SHIPPING_ADJUSTMENT_OVERRIDES: 'REST_SITE_SHIPPING_ADJUSTMENT_OVERRIDES',
            REST_SITE_SHIPPING_CONFIGURATION_SHIPPING_OPTIONS: 'REST_SITE_SHIPPING_CONFIGURATION_SHIPPING_OPTIONS',
            REST_SITE_SHIPPING_CONFIGURATIONS: 'REST_SITE_SHIPPING_CONFIGURATIONS',
            REST_SITE_STOREFRONT_CONFIGURATIONS: 'REST_SITE_STOREFRONT_CONFIGURATIONS',
            REST_SITES: 'REST_SITES',
            REST_STATES: 'REST_STATES',
            REST_STORED_ARTWORK_FILES: 'REST_STORED_ARTWORK_FILES',
            REST_SUPPLIERS: 'REST_SUPPLIERS',
            REST_SYSTEM_AUTHORIZENET_CONFIGURATIONS: 'REST_SYSTEM_AUTHORIZENET_CONFIGURATIONS',
            REST_SYSTEM_EDOC_CONFIGURATIONS: 'REST_SYSTEM_EDOC_CONFIGURATIONS',
            REST_SYSTEM_INTEGRATION_SERVER_CONFIGURATIONS: 'REST_SYSTEM_INTEGRATION_SERVER_CONFIGURATIONS',
            REST_SYSTEM_OFFERING_PROOFS: 'REST_SYSTEM_OFFERING_PROOFS',
            REST_SYSTEM_OFFERING_SITE_SHARE_METADATAS: 'REST_SYSTEM_OFFERING_SITE_SHARE_METADATAS',
            REST_SYSTEM_OFFERING_SITE_SHARE_PROOFS: 'REST_SYSTEM_OFFERING_SITE_SHARE_PROOFS',
            REST_SYSTEM_OFFERING_SITE_SHARE_TURNAROUND_TIMES: 'REST_SYSTEM_OFFERING_SITE_SHARE_TURNAROUND_TIMES',
            REST_SYSTEM_OFFERING_SITE_SHARES: 'REST_SYSTEM_OFFERING_SITE_SHARES',
            REST_SYSTEM_OFFERING_TURNAROUND_TIMES: 'REST_SYSTEM_OFFERING_TURNAROUND_TIMES',
            REST_SYSTEM_OFFERING_VARIANT_ADJUSTMENTS: 'REST_SYSTEM_OFFERING_VARIANT_ADJUSTMENTS',
            REST_SYSTEM_OFFERING_VARIANT_PRICE_TIERS: 'REST_SYSTEM_OFFERING_VARIANT_PRICE_TIERS',
            REST_SYSTEM_OFFERING_VARIANTS: 'REST_SYSTEM_OFFERING_VARIANTS',
            REST_SYSTEM_OFFERINGS: 'REST_SYSTEM_OFFERINGS',
            REST_SYSTEM_ROLE_SECURED_ENTITIES: 'REST_SYSTEM_ROLE_SECURED_ENTITIES',
            REST_SYSTEM_ROLES: 'REST_SYSTEM_ROLES',
            REST_SYSTEM_SCALING_MARKUP_POINTS: 'REST_SYSTEM_SCALING_MARKUP_POINTS',
            REST_SYSTEM_SCALING_MARKUPS: 'REST_SYSTEM_SCALING_MARKUPS',
            REST_SYSTEM_SHIPPING_CONFIGURATIONS: 'REST_SYSTEM_SHIPPING_CONFIGURATIONS',
            REST_TAX_RATES: 'REST_TAX_RATES',
            REST_TIPS: 'REST_TIPS',
            REST_TURNAROUND_TIMES: 'REST_TURNAROUND_TIMES',
            REST_USER_ADDRESSES: 'REST_USER_ADDRESSES',
            REST_USER_APPLICATION_SITE_ROLES: 'REST_USER_APPLICATION_SITE_ROLES',
            REST_USER_DISCOUNT_OFFERINGS: 'REST_USER_DISCOUNT_OFFERINGS',
            REST_USER_DISCOUNTS: 'REST_USER_DISCOUNTS',
            REST_USER_METADATAS: 'REST_USER_METADATAS',
            REST_USER_SEGMENTS: 'REST_USER_SEGMENTS',
            REST_USER_SITE_ROLES: 'REST_USER_SITE_ROLES',
            REST_USERS: 'REST_USERS',
            RETAIL_PRICING: 'RETAIL_PRICING',
            ROLES: 'ROLES',
            SCALING_MARKUPS: 'SCALING_MARKUPS',
            SEGMENTS: 'SEGMENTS',
            SHIPPING_SETTINGS: 'SHIPPING_SETTINGS',
            SHIPPING_VENDORS: 'SHIPPING_VENDORS',
            SITE_AUTO_USER_ASSIGNMENT_CONFIGURATION: 'SITE_AUTO_USER_ASSIGNMENT_CONFIGURATION',
            SITE_COST_AND_MARGIN: 'SITE_COST_AND_MARGIN',
            SITE_FULFILLMENT_CENTERS: 'SITE_FULFILLMENT_CENTERS',
            SITE_OFFERING_METADATA: 'SITE_OFFERING_METADATA',
            SITE_OFFERING_SHARES: 'SITE_OFFERING_SHARES',
            SITE_OFFERINGS: 'SITE_OFFERINGS',
            SITE_ORDER_METADATA: 'SITE_ORDER_METADATA',
            SITES: 'SITES',
            SYSTEM: 'SYSTEM',
            SYSTEM_UTILITY: 'SYSTEM_UTILITY',
            SYSTEM_FULFILLMENT_CENTERS: 'SYSTEM_FULFILLMENT_CENTERS',
            TAX_RATES: 'TAX_RATES',
            THEMES: 'THEMES',
            TURNAROUND_TIMES: 'TURNAROUND_TIMES',
            USER_DISCOUNTS: 'USER_DISCOUNTS',
            USER_METADATA: 'USER_METADATA',
            USER_SITE_SALES_REP: 'USER_SITE_SALES_REP',
            USERS: 'USERS'
        });
}());
